/* eslint-disable max-len */
import { AccountRoutes } from '../../../submodules/common/enums/routes/account-routes.enum';
import { AuthRoutes } from '../../../submodules/common/enums/routes/auth-routes.enum';
import { IAdminLoginRequestDTO } from '../../../submodules/common/interfaces/dto/auth/iadmin-login-request.interface';
import { IAdminLoginResponseDTO } from '../../../submodules/common/interfaces/dto/auth/iadmin-login-response.interfaces';
import { IApiResponseGenericDTO } from '../../../submodules/common/interfaces/dto/common/iapi-response.interface';
import { EMethod } from '../utils';
import { adminApi } from './adminApi';

export const authApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<IApiResponseGenericDTO<IAdminLoginResponseDTO>, IAdminLoginRequestDTO>({
      query: (body) => ({
        url: `${AuthRoutes.BasePrefix}/${AuthRoutes.Login}`,
        method: EMethod.POST,
        body,
      }),
    }),
    recoveryUser: builder.mutation<IApiResponseGenericDTO<IAdminLoginResponseDTO>, undefined>({
      query: (body) => ({
        url: `${AccountRoutes.BasePrefix}/${AccountRoutes.GetOwnAccountInfo}`,
        method: EMethod.GET,
        body,
      }),
    }),
  }),
});


export const { useLoginMutation, useRecoveryUserMutation } = authApi;
