import { OptionsType } from '../../types/selectTypes';
import { splitByCapitalLetters } from '../../utils/general.utils';

export const optionsTitle = {
  create: 'Question & Answer',
  update: 'Question & Answer',
  info: 'Question & Answer',
};

export interface IInfoObj {
  id: string,
  question: string,
  answer: string,
  link: string,
  title: string,
  category: OptionsType<number> | null,
}

export const defaultSelectedItem: IInfoObj = {
  id: '',
  question: '',
  answer: '',
  link: '',
  title: '',
  category: null,
};

export const prepareRowInfo = (data) => {
  const id = data?.id ?? '';
  const question = data?.question ?? '';
  const answer = data?.answer ?? '';
  const link = data?.metadata?.link ?? '';
  const title = data?.metadata?.title ?? '';

  const isReference = data?.referenceCategory && Object.keys(data?.referenceCategory);

  const category = {
    value: data?.referenceCategory,
    label: splitByCapitalLetters(String(data?.referenceCategory ?? '')),
  };

  return { id, question, answer, link, title, category: isReference ? category : null };
};

export const prepareDataToCreateUpdate = (data) => {
  const answer = data?.answer ?? '';
  const question = data?.question ?? '';
  const link = data?.link ?? '';
  const title = data?.title ?? '';
  const metadata = (!!link && !!title) ? { link, title} : undefined;
  return { answer, question, metadata };
};

export enum ESuccessMessages {
  CREATE = 'Question & Answer has been created successfully',
  DELETE = 'Question & Answer has been delete successfully',
  UPDATE = 'Question & Answer has been update successfully',
}
