import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IChatAdminMessageAccept, IInitialChatAdminState } from '../../types/reducerTypes/chatAdminTypes';
import { chatAdminApi } from '../api/adminApi/chatAdmin';
import { EHelpfulField, EPublisher } from '../../types/publisher.enum';
import { IMessageData } from '../../types/messagesTypes';
import { defaultMessageData } from '../../utils/responseUtils';

export const initialState: IInitialChatAdminState = {
  isChatSessionOpen: false,
  messages: [],
  isFetching: false,
  sessionId: null,
  greeting: null,
  error: defaultMessageData,

  actionsFetching: false,
};

const chatAdminSlice = createSlice({
  name: 'chatAdmin',
  initialState,
  reducers: {
    setChatSession: (state, action: PayloadAction<boolean>) => {
      state.isChatSessionOpen = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    resetStateChatAdmin: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        chatAdminApi.endpoints.startChatAdminSession.matchPending,
        (state) => {
          state.isFetching = true;
          state.error = defaultMessageData;
        },
      )
      .addMatcher(
        chatAdminApi.endpoints.startChatAdminSession.matchFulfilled,
        (state, action: PayloadAction<{ data: { id: string, greeting: string } }>) => {
          const greeting = action.payload.data?.greeting ?? null;
          state.sessionId = action.payload.data.id;
          state.greeting = greeting;
          state.isFetching = false;
          state.isChatSessionOpen = true;
          if (greeting) {
            state.messages = [{ message: greeting, publisher: EPublisher.You }];
          }
        },
      )
      .addMatcher(
        chatAdminApi.endpoints.startChatAdminSession.matchRejected,
        (state, action: PayloadAction<{ messageData: IMessageData }>) => {
          state.sessionId = null;
          state.isFetching = false;
          state.isChatSessionOpen = false;
          state.error = { messageData: action.payload.messageData } as unknown as IMessageData;
        },
      )
      .addMatcher(
        chatAdminApi.endpoints.sendMessage.matchPending,
        (state) => {
          state.isFetching = true;
          state.error = defaultMessageData;
        },
      )
      .addMatcher(
        chatAdminApi.endpoints.sendMessage.matchRejected,
        (state, action: PayloadAction<{ messageData: IMessageData }>) => {
          state.isFetching = false;
          state.error = { messageData: action.payload.messageData } as unknown as IMessageData;
        },
      )
      .addMatcher(
        chatAdminApi.endpoints.sendMessage.matchFulfilled,
        (state, action: PayloadAction<IChatAdminMessageAccept>) => {
          state.isFetching = false;
          const messages = [...state.messages];
          messages.push({ ...action.payload });
          /**
           * * if need hidden prev like dislike
           * * messages[messages.length - 3]['isHelpfulCheckedHidden'] = false;
          */

          state.messages = messages;
        },
      )
      .addMatcher(
        chatAdminApi.endpoints.likeMessage.matchPending,
        (state) => {
          state.actionsFetching = true;
          state.error = defaultMessageData;
        },
      )
      .addMatcher(
        chatAdminApi.endpoints.likeMessage.matchRejected,
        (state, action: PayloadAction<{ messageData: IMessageData }>) => {
          state.actionsFetching = false;
          state.error = { messageData: action.payload.messageData } as unknown as IMessageData;
        },
      )
      .addMatcher(
        chatAdminApi.endpoints.likeMessage.matchFulfilled,
        (state, action: PayloadAction<{ data: boolean, messageId: number }>) => {
          state.actionsFetching = false;
          state.messages = state.messages.map((m) => {
            if (m?.['id'] === action.payload.messageId) {
              return {
                ...m,
                helpfulCheckedField: EHelpfulField.YES,
                isHelpfulCheckedHidden: true,
              };
            }
            return m;
          });
        },
      )
      .addMatcher(
        chatAdminApi.endpoints.dislikeMessage.matchPending,
        (state) => {
          state.actionsFetching = true;
          state.error = defaultMessageData;
        },
      )
      .addMatcher(
        chatAdminApi.endpoints.dislikeMessage.matchRejected,
        (state, action: PayloadAction<{ messageData: IMessageData }>) => {
          state.actionsFetching = false;
          state.error = { messageData: action.payload.messageData } as unknown as IMessageData;
        },
      )
      .addMatcher(
        chatAdminApi.endpoints.dislikeMessage.matchFulfilled,
        (state, action: PayloadAction<{ data: boolean, messageId: number }>) => {
          state.actionsFetching = false;
          state.messages = state.messages.map((m) => {
            if (m?.['id'] === action.payload.messageId) {
              return {
                ...m,
                helpfulCheckedField: EHelpfulField.NO,
              };
            }
            return m;
          });
        },
      );
  },
});

export const chatAdminReducer = chatAdminSlice.reducer;
export const chatAdminActions = chatAdminSlice.actions;
