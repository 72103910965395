import { IPdfDocumentDTO } from '../../submodules/common/interfaces/dto/pdf-document/pdf-document.interface';
import { EMessageType, IMessageData } from '../../types/messagesTypes';

export const optionsTitle = {
  create: 'PDF Document',
  update: 'PDF Document',
  info: 'PDF Document',
};

export enum ESuccessMessages {
  CREATE = 'PDF Document has been created successfully',
  DELETE = 'PDF Document has been deleted successfully',
  UPDATE = 'PDF Document has been updated successfully',
}

export const handleResponse = (response: IMessageData, callback) => {
  if (response?.messageData?.messageType === EMessageType.SUCCESS) {
    callback();
  }
};

export const defaultFormValues = (
  values: Record<string, unknown>,
  provideDefaultValues?: Record<string, unknown>
): Record<string, unknown> => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (provideDefaultValues) {
      return {
        ...acc,
        [key]:
          value ?? key in provideDefaultValues ? provideDefaultValues[key] : '',
      };
    }
    return { ...acc, [key]: value ?? '' };
  }, {});
};

export const title = 'PDF Documents';

export const defaultSelectedItem: IPdfDocumentDTO = {
  id: 0,
  created: '',
  company: '',
  model: '',
  link: '',
  content: '',
};

export const stringifyFile = (obj = {}) => {
  const replacer = [] as string[];
  for (const key in obj) {
    replacer.push(key);
  }
  return JSON.parse(JSON.stringify(obj, replacer));
};
