import { AdminChatRoutes } from '../../../submodules/common/enums/routes/admin-chat-routes.enum';
import { IExtendedAnswerToQuestionDTO } from '../../../submodules/common/interfaces/dto/chat/iextended-answer-to-question.interface';
import { IApiResponseGenericDTO } from '../../../submodules/common/interfaces/dto/common/iapi-response.interface';
import { EPublisher } from '../../../types/publisher.enum';
import { IChatAdminMessageAccept } from '../../../types/reducerTypes/chatAdminTypes';
import { EMethod } from '../utils';
import { adminApi } from './adminApi';

export const chatAdminApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    startChatAdminSession: builder.mutation<{ data: { id: string, greeting: string } }, undefined>({
      query: () => ({
        url: `${AdminChatRoutes.BasePrefix}/${AdminChatRoutes.CreateExtendedChatSession}`,
        method: EMethod.POST,
      }),
    }),
    sendMessage: builder.mutation<IChatAdminMessageAccept, { id: string, body: { message: string } }>({
      query: ({ id, body }) => ({
        url: `${AdminChatRoutes.BasePrefix}/${AdminChatRoutes.SendQuestion.replace('/:sessionId', `/${id}`)}`,
        method: EMethod.POST,
        body,
      }),
      transformResponse: (response: { data: IExtendedAnswerToQuestionDTO }) => ({
        ...response.data,
        publisher: EPublisher.AI,
        helpfulCheckedField: null,
        isHelpfulCheckedHidden: true,
      }),
    }),
    likeMessage: builder.mutation<{ data: boolean, messageId: number }, { messageId: number, sessionId: string }>({
      query: ({ messageId, sessionId }) => ({
        url: `${AdminChatRoutes.BasePrefix}/${AdminChatRoutes.LikeAnswer
          .replace('/:sessionId', `/${sessionId}`).replace('/:messageId', `/${messageId}`)}`,
        method: EMethod.PUT,
      }),
      transformResponse: (response: IApiResponseGenericDTO<boolean>, _, arg) => ({
        data: response.data, messageId: arg.messageId,
      }),
    }),
    dislikeMessage: builder.mutation<{ data: boolean, messageId: number }, { messageId: number, sessionId: string }>({
      query: ({ messageId, sessionId }) => ({
        url: `${AdminChatRoutes.BasePrefix}/${AdminChatRoutes.DislikeAnswer
          .replace('/:sessionId', `/${sessionId}`).replace('/:messageId', `/${messageId}`)}`,
        method: EMethod.PUT,
      }),
      transformResponse: (response: IApiResponseGenericDTO<boolean>, _, arg) => ({
        data: response.data, messageId: arg.messageId,
      }),
    }),
  }),
});

export const {
  useSendMessageMutation, useStartChatAdminSessionMutation,
  useLikeMessageMutation, useDislikeMessageMutation,
} = chatAdminApi;
