export enum AdminChatRoutes {
  BasePrefix = "chats",
  GetMessages = "messages/:sessionId",
  CreateExtendedChatSession = "sessions",
  SendQuestion = "questions/send/:sessionId",
  GetSessionToken = "sessions/token",
  GetSessionsCSV = "sessions/csv/:token",
  LikeAnswer = "answer/:messageId/:sessionId/like",
  DislikeAnswer = "answer/:messageId/:sessionId/dislike",
  GetSessionById = "sessions/:sessionId"
}
