import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authReducer as authReducer } from '../reducers/authSlice';
import { adminApi } from '../api/adminApi/adminApi';
import logger from 'redux-logger';
import { chatAdminReducer } from '../reducers/chatAdminSlice';


const reducers = combineReducers(
  {
    auth: authReducer,
    chatAdmin: chatAdminReducer,

    [adminApi.reducerPath]: adminApi.reducer,
  });

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => {
    return process.env.NODE_ENV === 'development' ?
      getDefaultMiddleware().concat(logger).concat(adminApi.middleware) :
      getDefaultMiddleware().concat(adminApi.middleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
