import { ReactElement } from 'react';

export type TSettings = {
  content: string,
  company: string,
  model: string,
};

export type TMessageValues = {
  messageType: string,
  messages: Array<string | ReactElement>,
  isMarkHeader?: boolean,
  defaultErrorText?: string
  params?: { [k: string]: number }
};

export enum ESuccessMessagesUpdate {
  UPDATE = 'PDF Document has been updated successfully',
}

export const defaultMessageData = { messageType: '', messages: [] };
