import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logOut } from '../../reducers/authSlice';
import { ELocalStorage } from '../../../types/localStorageTypes';
import { EMethod, EStatusCode, headers, isAccessForbidden } from '../utils';
import { getBody, getPrepareHeaders, tagTypes } from './utils';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { IApiResponseDTO, IApiResponseGenericDTO } from '../../../submodules/common/interfaces/dto/common/iapi-response.interface';
import { AuthRoutes } from '../../../submodules/common/enums/routes/auth-routes.enum';
import { IAdminLoginResponseDTO } from '../../../submodules/common/interfaces/dto/auth/iadmin-login-response.interfaces';
import { setLocaleStorageService } from '../../../services/localStorageService';
import { prepareErrorMessage } from '../../../utils/responseUtils';
import { appConfig } from '../../../infrastructure/app-config.infrastructure';
import { BaseRoutes } from '../../../submodules/common/enums/routes/base-routes.enum';

const baseQuery = fetchBaseQuery({
  baseUrl: `${appConfig.AI_API_BASE_URL}${BaseRoutes.AdminPanel}`,
  headers,
  prepareHeaders: (headers: Headers) => getPrepareHeaders(headers),
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions) as
    QueryReturnValue<IApiResponseDTO, { data: IApiResponseDTO }, unknown>;

  const error = result?.error;
  if (error && error?.['originalStatus'] != EStatusCode.SUCCESSES) {
    if (isAccessForbidden(error?.data?.statusCode) && args?.url !== `${AuthRoutes.BasePrefix}/${AuthRoutes.Login}`) {
      const refreshTokenResult =
        await baseQuery({
          url: `${AuthRoutes.BasePrefix}/${AuthRoutes.RefreshToken}`,
          method: EMethod.PUT,
          body: getBody(),
        }, api, extraOptions) as
        QueryReturnValue<IApiResponseGenericDTO<IAdminLoginResponseDTO>, { data: IApiResponseDTO }, unknown>;

      if (refreshTokenResult?.data) {
        const { accessToken, refreshToken } = refreshTokenResult.data.data.access;

        setLocaleStorageService({
          [ELocalStorage.AdminAccessToken]: accessToken,
          [ELocalStorage.AdminRefreshToken]: refreshToken,
        });

        result = await baseQuery(args, api, extraOptions) as
          QueryReturnValue<IApiResponseDTO, { data: IApiResponseDTO }, unknown>;
        return {
          data: { ...result.data, initialFetching: true },
        };
      } else {
        api.dispatch(logOut());
        return;
      }
    }
    const status = error?.data?.statusCode;
    const errorCode = error?.data?.error?.errorCode;
    const messageData = prepareErrorMessage(status, errorCode);

    return {
      error: { ...error, messageData, initialFetching: true },
    };
  }
  if (error && error?.['originalStatus'] == EStatusCode.SUCCESSES) {
    return {
      data: { ...result.error, initialFetching: true },
    };
  }
  return {
    data: { ...result.data, initialFetching: true },
  };
};

export const adminApi = createApi({
  reducerPath: 'adminApi',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseQuery: baseQueryWithReAuth as any,
  endpoints: () => ({}),
  tagTypes,
  keepUnusedDataFor: 0,
});
