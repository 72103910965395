import { IQuestionCategoryDTO } from '../../submodules/common/interfaces/dto/question-category/iquestion-category.interface';

export const optionsTitle = {
  create: 'Category',
  update: 'Category',
  info: 'Category',
};

export const prepareDataToCreateUpdate = (data) => {
  const title = data?.title ?? '';
  const description = data?.description ?? '';
  const showReference = data?.showReference ?? false;
  const showLikeDislike = data?.showLikeDislike ?? false;
  return { title, description, showReference, showLikeDislike };
};

export enum ESuccessMessages {
  CREATE = 'Category has been created successfully',
  DELETE = 'Category has been deleted successfully',
  UPDATE = 'Category has been updated successfully',
}

export const title = 'Question Categories';

export const defaultSelectedItem: IQuestionCategoryDTO = {
  created: '',
  category: '',
  id: '',
};
