import { resetSessionChatApi } from './api/adminApi/sessionChatApi';
import { chatAdminActions } from './reducers/chatAdminSlice';
import { setUser, logOut, setInitialFetching, setIsLogin, setIsSetSessionId } from './reducers/authSlice';
import { resetPdfDocumentsApi } from './api/adminApi/pdfDocuments';
import { resetQuestionAnswerApi } from './api/adminApi/questionAnswerApi';
import { resetPdfDocContentApi } from './api/adminApi/pdfDocContent';
import { resetQuestionCategoriesApi } from './api/adminApi/questionCategoriesApi';
import { resetPromptApi } from './api/adminApi/promptApi';

export const rootActions = {
  setUser, logOut, setInitialFetching, setIsLogin, setIsSetSessionId,
  ...chatAdminActions,
  resetSessionChatApi,
  resetPdfDocumentsApi,
  resetQuestionAnswerApi,
  resetPdfDocContentApi,
  resetQuestionCategoriesApi,
  resetPromptApi,
};
