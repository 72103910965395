import { authApi } from '../redux/api/adminApi/authApi';
import { logOut, setInitialFetching } from '../redux/reducers/authSlice';
import { store } from '../redux/store';
import { ELocalStorage, EToken } from '../types/localStorageTypes';
import { getTokenByType } from '../utils/localStorageUtil';
import { getLocalStorageService } from './localStorageService';

export const authServiceSetting = async () => {
  const dispatch = store.dispatch;
  if (getLocalStorageService(EToken.TOKENS)) {
    await dispatch(authApi.endpoints.recoveryUser.initiate(undefined, undefined));
  }
  dispatch(setInitialFetching(true));
};

export const authService = () => {
  const dispatch = store.dispatch;
  const initialFetching = store.getState().auth.initialFetching;

  const adminAccessToken = getTokenByType(ELocalStorage.AdminAccessToken);
  const adminRefreshToken = getTokenByType(ELocalStorage.AdminRefreshToken);

  if (adminAccessToken && adminRefreshToken) {
    if (initialFetching) {
      dispatch(authApi.endpoints.recoveryUser.initiate(undefined, undefined));
    }
  } else {
    dispatch(logOut());
  }
};
